/* eslint-disable react/jsx-no-bind, import/prefer-default-export */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import './finalmessage.css';
import Nav from '../../components/Nav/Nav';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import Button from '../../components/Button/Button';
import PageNames from '../../utils/PageNames';
import MetricsContext from '../../context/MetricsContext';
import PageTypes from '../../utils/PageTypes';

export default function FinalMessage() {
  const { t } = useTranslation();
  const location = useLocation();
  const feedback = location.state?.feedback;
  const navigate = useNavigate();
  const { useUpdateUserMetric } = useContext(MetricsContext);
  const startDate = new Date();

  const updateMetrics = btn => {
    const dateFinal = new Date();
    useUpdateUserMetric({
      name: PageNames.FINAL_MESSAGE_PAGE,
      pageType: PageTypes.DEFAULT,
      duration: Math.abs(dateFinal - startDate),
      interactions: [
        {
          name: btn,
          time: dateFinal,
        },
      ],
    });
  };

  function handleRedirect() {
    updateMetrics('Go back to homepage');
    navigate('/homepage');
  }

  const sessionId = localStorage.getItem('sessionId');
  useEffect(() => {
    useUpdateUserMetric({
      name: PageNames.FINAL_MESSAGE_PAGE,
      pageType: PageTypes.DEFAULT,
    });
  }, [sessionId]);

  return (
    <div className="container">
      <Nav logo closeButton />
      <ColoredBar />
      <div className="containerContent finalMessage__content">
        <div>
          <h3 className="finalMessage__title">{t('finalmessagepage.h3')}</h3>
          {feedback ? (
            <p className="finalMessage__paragraph">
              {t('finalmessagepage.yesfeedback')}
            </p>
          ) : (
            <p className="finalMessage__paragraph">
              {t('finalmessagepage.nofeedback')}
            </p>
          )}
        </div>
        <Button
          width="fullWidth"
          class="finalMessage__button"
          hierarchy="primary"
          onClick={handleRedirect}
        >
          {t('finalmessagepage.homebtn')}
        </Button>
      </div>
    </div>
  );
}
