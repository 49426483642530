import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './disclaimer.css';
import Nav from '../../components/Nav/Nav';
import Button from '../../components/Button/Button';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import Modal from '../../components/Modal/Modal';
import MetricsContext from '../../context/MetricsContext';
import PageNames from '../../utils/PageNames';
import PageTypes from '../../utils/PageTypes';

export default function Disclaimer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalIsShown, setModalIsShown] = useState(false);
  const { useUpdateUserMetric } = useContext(MetricsContext);
  const startDate = new Date();
  const sessionId = localStorage.getItem('sessionId');
  useEffect(() => {
    useUpdateUserMetric({
      name: PageNames.DISCLAIMER_PAGE,
      pageType: PageTypes.DEFAULT,
    });
  }, [sessionId]);

  const updateMetrics = btn => {
    const dateFinal = new Date();
    useUpdateUserMetric({
      name: PageNames.DISCLAIMER_PAGE,
      pageType: PageTypes.DEFAULT,
      duration: Math.abs(dateFinal - startDate),
      interactions: [
        {
          name: btn,
          time: dateFinal,
        },
      ],
    });
  };

  const showModalHandler = () => {
    updateMetrics('Disagree and leave');
    setModalIsShown(true);
  };

  const hideModalHandler = () => {
    setModalIsShown(false);
  };

  const handleContinue = () => {
    updateMetrics('Agree and continue');
    navigate('/emergency');
  };

  return (
    <div className="container">
      <Nav logo closeButton />
      <ColoredBar />
      <div className="containerContent disclaimer__page">
        <p>{t('disclaimerpage.paragraph1')}</p>
        <br />
        <p>{t('disclaimerpage.paragraph2')}</p>
        <div className="disclaimer__bottomwrapper">
          <Button
            width="fullWidth"
            hierarchy="primary"
            onClick={handleContinue}
            additionalClass=" agree"
          >
            {t('disclaimerpage.button1')}
          </Button>
          <Button
            width="fullWidth"
            hierarchy="tertiary"
            onClick={showModalHandler}
          >
            {t('disclaimerpage.button2')}
          </Button>
        </div>
      </div>
      {modalIsShown && <Modal onCloseModal={hideModalHandler} />}
    </div>
  );
}
