/* eslint-disable react/jsx-no-bind */
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { addRatingAPI } from '../../API/api';
import './feedback.css';
import Nav from '../../components/Nav/Nav';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import TextArea from '../../components/TextArea/TextArea';
import Button from '../../components/Button/Button';
import Rate from '../../components/Rating/Rating';

function Feedback() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeBtn, setActiveBtn] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [message, setMessage] = useState('');
  const [rating, setRating] = useState(0);
  const mutationRef = useRef(rating);
  const journeyId = location.state?.completedJourneyId;

  useEffect(() => {
    mutationRef.current = rating;

    if (mutationRef.current > 0) {
      setActiveBtn(true);
    } else {
      setActiveBtn(false);
    }
  }, [rating]);

  function handleChange(e) {
    setWordCount(e.target.value.length);
    setMessage(e.target.value);
  }
  const ratingChanged = newRating => {
    setRating(newRating);
  };

  async function sendFeedback() {
    const userId = localStorage.getItem('sessionId');
    if (userId && journeyId && rating) {
      const data = await addRatingAPI(
        userId,
        journeyId,
        rating,
        message,
      );
      if (typeof data === 'string') {
        navigate('/finalMessage', { state: { feedback: false } });
        return;
      }
      if (!data.ok) {
        const response = await data.json();
        navigate('/error', {
          state: { code: data.status, message: JSON.stringify(response) },
        });
      } else {
        navigate('/finalMessage', { state: { feedback: true } });
      }
    } else {
      navigate('/finalMessage', { state: { feedback: false } });
    }
  }

  function noFeedback() {
    navigate('/finalMessage', {
      state: {
        feedback: false,
      },
    });
  }

  return (
    <div className="container">
      <Nav logo closeButton />
      <ColoredBar />
      <div className="containerContent feedbackcontainer">
        <div className="feedbackcontent">
          <h3 className="feedbacktitle">{t('feedbackpage.h3')}</h3>
          <p className="feedbackp">{t('feedbackpage.p')}</p>
          <Rate onRating={ratingChanged} rating={rating} />
          <div className="feedback__textarea">
            <TextArea
              label={t('feedbackpage.textarealabel')}
              name="feedback"
              placeholder={t('feedbackpage.textareaplaceholder')}
              onChange={handleChange}
              wordCount={wordCount}
              value={message}
            />
          </div>
        </div>
        <div className="feedback__footer">
          <Button
            type="submit"
            disabled={!activeBtn}
            onClick={sendFeedback}
            hierarchy="primary"
            width="fullWidth"
          >
            {t('feedbackpage.button1')}
          </Button>
          <Button onClick={noFeedback} hierarchy="tertiary" width="fullWidth">
            {t('feedbackpage.button2')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Feedback;
