import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logoPic from '../../assets/designSystem/logo/symbol-default.svg';
import menuPic from '../../assets/designSystem/icons/menu.svg';
import leftArrowPic from '../../assets/designSystem/icons/arrow/left.svg';
import closeButtonPic from '../../assets/designSystem/icons/close.svg';
import Modal from '../Modal/Modal';
import './nav.css';

export default function Nav({
  homepage,
  backArrow,
  backArrowCallback,
  label,
  logo,
  closeButton,
}) {
  const navigate = useNavigate();
  let homepageContent = '';
  let firstContent = '';
  let midContent = '';
  let lastContent = '';
  const [modalIsShown, setModalIsShown] = useState(false);

  const showModalHandler = () => {
    setModalIsShown(true);
  };

  const hideModalHandler = () => {
    setModalIsShown(false);
  };

  const backArrowHandler = () => {
    navigate(-1);
  };

  const menuButtonHandler = () => {
    navigate('/menu');
  };

  if (homepage) {
    homepageContent = (
      <>
        <img src={logoPic} alt="logo" width={40} height={20} />
        <img
          src={menuPic}
          alt="menu"
          role="presentation"
          onClick={menuButtonHandler}
        />
      </>
    );
  } else {
    if (backArrow) {
      firstContent = (
        <img
          src={leftArrowPic}
          alt="left arrow"
          role="presentation"
          onClick={backArrowCallback || backArrowHandler}
        />
      );
    } else {
      firstContent = <div className="nav__empty-div" />;
    }

    if (label || logo) {
      if (label) {
        midContent = <h4 className="nav__label">{label}</h4>;
      }
      if (logo) {
        midContent = <img src={logoPic} alt="logo" width={40} height={20} />;
      }
    } else {
      midContent = <div className="nav__empty-div" />;
    }

    if (closeButton) {
      lastContent = (
        <img
          src={closeButtonPic}
          alt="close button"
          role="presentation"
          onClick={showModalHandler}
        />
      );
    } else {
      lastContent = <div className="nav__empty-div" />;
    }
  }

  return (
    <nav>
      {homepageContent}
      {firstContent}
      {midContent}
      {lastContent}
      {modalIsShown && <Modal onCloseModal={hideModalHandler} />}
    </nav>
  );
}
