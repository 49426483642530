export { default as AgeGroup } from './AgeGroup/AgeGroup';
export { default as Disclaimer } from './Disclaimer/Disclaimer';
export { default as Emergency } from './Emergency/Emergency';
export { default as HomePage } from './HomePage/HomePage';
export { default as Questions } from './Questions/Questions';
export { default as Splashscreen } from './Splashscreen/Splashscreen';
export { default as Symptom } from './Symptom/Symptom';
export { default as FinalMessage } from './FinalMessage/FinalMessage';
export { default as Language } from './Language/Language';
export { default as Onboarding } from './Onboarding/Onboarding';
export { default as OtherSymptom } from './OtherSymptom/OtherSymptom';
export { default as Agreement } from './Agreement/Agreement';
export { default as PrivacyPolicy } from './PrivacyPolicy/PrivacyPolicy';
export { default as TermsAndConditions } from './TermsAndConditions/TermsAndConditions';
export { default as Menu } from './Menu/Menu';
export { default as Output } from './Output/Output';
export { default as Feedback } from './Feedback/Feedback';
export { default as Loading } from './Loading/Loading';
export { default as ErrorHandler } from './ErrorHandler/ErrorHandler';
export { default as ClinicalFeedback } from './ClinicalFeedback/ClinicalFeedback';
export { default as Test } from './Test/Test';
