/* eslint-disable react/jsx-no-bind */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './symptom.css';
import { useLocation, useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import Nav from '../../components/Nav/Nav';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import SquareRadio from '../../components/Radio/SquareRadio';
import { getSymptoms } from '../../API/api';
import PageNames from '../../utils/PageNames';
import PageTypes from '../../utils/PageTypes';
import MetricsContext from '../../context/MetricsContext';
import ButtonNames from '../../utils/ButtonNames';

export default function Symptom() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { language } = i18n;
  const ageGroup = location.state?.ageGroup;
  const [symptoms, setSymptoms] = useState([]);
  const [loading, setLoading] = useState(true);
  const { useUpdateUserMetric } = useContext(MetricsContext);
  const startDate = new Date();
  const sessionId = localStorage.getItem('sessionId');
  useEffect(() => {
    if (ageGroup === undefined) navigate('/ageGroup');
    getSymptoms(language, ageGroup)
      .then(response => {
        response.json().then(data => {
          if (data.length) {
            setSymptoms(data);
            setLoading(false);
            useUpdateUserMetric({
              name: PageNames.SYMPTOM_PAGE,
              pageType: PageTypes.SYMPTOM_METRIC,
              symptomList: data.map(symptom => symptom.name),
            });
          } else {
            setLoading(false);
          }
        });
      })
      .catch(error => {
        throw Error(error);
      });
  }, [sessionId]);

  function handleRedirect(e) {
    const symptomValue = e.target.value;
    const dateFinal = new Date();
    useUpdateUserMetric({
      name: PageNames.SYMPTOM_PAGE,
      pageType: PageTypes.SYMPTOM_METRIC,
      duration: Math.abs(dateFinal - startDate),
      interactions: [
        {
          name: `${ButtonNames.CONTINUE} ${symptomValue}`,
          time: new Date(),
        },
      ],
    });
    setTimeout(() => {
      if (symptomValue !== '') {
        navigate('/questions', {
          state: {
            language,
            ageGroup,
            symptom: symptomValue,
          },
        });
      }
    }, 250);
  }

  const otherSymptomRedirect = () => {
    const dateFinal = new Date();
    useUpdateUserMetric({
      name: PageNames.SYMPTOM_PAGE,
      pageType: PageTypes.SYMPTOM_METRIC,
      duration: Math.abs(dateFinal - startDate),
      interactions: [
        {
          name: ButtonNames.OTHER,
          time: new Date(),
        },
      ],
    });
    setTimeout(() => {
      navigate('/otherSymptom', {
        state: {
          ageGroup,
        },
      });
    }, 250);
  };

  return (
    <div className="container">
      <Nav backArrow logo closeButton />
      <ColoredBar />
      <div className="containerContent symptom__content">
        <h3>{t('symptompage.h3')}</h3>
        {loading && <p className="load" />}
        <div className="symptom__buttons">
          {!loading
            && symptoms.length >= 1
            && symptoms.map(symptom => (
              <SquareRadio
                // eslint-disable-next-line no-underscore-dangle
                key={symptom._id}
                name="option"
                value={symptom.name}
                label={t(`symptompage.${symptom.name}`)}
                showFlag={false}
                onClick={handleRedirect}
              />
            ))}
          {!loading && symptoms.length >= 0 && (
            <SquareRadio
              name="option"
              value="Other"
              label={t('symptompage.option4')}
              showFlag={false}
              onClick={otherSymptomRedirect}
            />
          )}
        </div>
      </div>
    </div>
  );
}
