/* eslint-disable consistent-return */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import slideImg1 from '../../assets/designSystem/icons/onboarding1.png';
import slideImg2 from '../../assets/designSystem/icons/onboarding2.png';
import slideImg3 from '../../assets/designSystem/icons/onboarding3.gif';
import Button from '../../components/Button/Button';
import './onboarding.css';
import MetricsContext from '../../context/MetricsContext';
import PageNames from '../../utils/PageNames';
import PageTypes from '../../utils/PageTypes';
import ButtonNames from '../../utils/ButtonNames';

function Onboarding() {
  const { t } = useTranslation();
  const startDate = new Date();
  const slides = [
    {
      header: "Check you child's symptom",
      paragraph:
        'Select their symptom based on what your child is experiencing.',
      img: slideImg1,
    },
    {
      header: 'Know what the symptom mean',
      paragraph:
        'Understand what your child is feeling by answering a few questions.',
      img: slideImg2,
    },
    {
      header: "Manage your child's health",
      paragraph:
        'Get trusted medical advice to help guide you on your next actions.',
      img: slideImg3,
    },
  ];

  const { useUpdateUserMetric, storeLocalMetrics } = useContext(MetricsContext);

  const sessionId = localStorage.getItem('sessionId');

  useEffect(() => {
    if (sessionId) {
      useUpdateUserMetric({
        name: PageNames.ONBOARDING_PAGE,
        pageType: PageTypes.DEFAULT,
      });
    } else {
      storeLocalMetrics({
        name: PageNames.ONBOARDING_PAGE,
        pageType: PageTypes.DEFAULT,
      });
    }
  }, [sessionId]);

  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const updateMetrics = btn => {
    const dateFinal = new Date();
    if (sessionId) {
      useUpdateUserMetric({
        name: PageNames.ONBOARDING_PAGE,
        pageType: PageTypes.DEFAULT,
        duration: Math.abs(dateFinal - startDate),
        interactions: [
          {
            name: btn,
            time: dateFinal,
          },
        ],
      });
    } else {
      storeLocalMetrics({
        name: PageNames.ONBOARDING_PAGE,
        pageType: PageTypes.DEFAULT,
        duration: Math.abs(dateFinal - startDate),
        interactions: [
          {
            name: btn,
            time: dateFinal,
          },
        ],
      });
    }
  };

  const handleGoToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    updateMetrics(ButtonNames.CONTINUE);
    if (isLastSlide) {
      navigate('/agreement');
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleGoToSlide = slideIndex => {
    updateMetrics(ButtonNames.CONTINUE);
    setCurrentIndex(slideIndex);
  };

  const handleRedirect = () => {
    updateMetrics(ButtonNames.SKIP);
    navigate('/agreement');
  };

  const renderheader = () => {
    switch (currentIndex) {
      case 0:
        return (
          <div>
            <h3 className="onboarding__header">
              {t('onboardingpage.slide1.header')}
            </h3>
            <p className="onboarding__text">
              {t('onboardingpage.slide1.paragraph')}
            </p>
          </div>
        );
      case 1:
        return (
          <div>
            <h3 className="onboarding__header">
              {t('onboardingpage.slide2.header')}
            </h3>
            <p className="onboarding__text">
              {t('onboardingpage.slide2.paragraph')}
            </p>
          </div>
        );
      case 2:
        return (
          <div>
            <h3 className="onboarding__header">
              {t('onboardingpage.slide3.header')}
            </h3>
            <p className="onboarding__text">
              {t('onboardingpage.slide3.paragraph')}
            </p>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="container">
      <div className="onboarding__slideshowContainer">
        <div className="onboarding__slide">
          {renderheader()}
          {/* <div> */}
          <img
            className="onboarding__img"
            src={slides[currentIndex].img}
            alt="slide"
          />
          {/* </div> */}
        </div>

        <div className="onboarding__buttons">
          <Button
            width="smallWidth"
            hierarchy="tertiary"
            onClick={handleRedirect}
            additionalClass=" skip"
          >
            {t('onboardingpage.skip')}
          </Button>
          <div className="onboarding__dots">
            <span
              className={`onboarding__dot ${
                currentIndex === 0 ? 'onboarding__activeDot' : ''
              }`}
              aria-hidden="true"
              onClick={() => handleGoToSlide(0)}
            />
            <span
              className={`onboarding__dot ${
                currentIndex === 1 ? 'onboarding__activeDot' : ''
              }`}
              aria-hidden="true"
              onClick={() => handleGoToSlide(1)}
            />
            <span
              className={`onboarding__dot ${
                currentIndex === 2 ? 'onboarding__activeDot' : ''
              }`}
              aria-hidden="true"
              onClick={() => handleGoToSlide(2)}
            />
          </div>
          <Button
            width="smallWidth"
            hierarchy="primary"
            onClick={handleGoToNext}
          >
            {t('onboardingpage.next')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Onboarding;
