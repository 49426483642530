/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import Button from '../../../components/Button/Button';
import closeButtonPic from '../../../assets/designSystem/icons/close.svg';
import './popup.css';

function Popup({ title, text, tableData, callback }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="modal__backdrop" />
      <div className="modal__popup">
        <div className="popup__container">
          <img
            src={closeButtonPic}
            alt="close button"
            className="popup__closeBtn"
            role="presentation"
            width="24"
            onClick={callback}
          />
          <div className="popup__content">
            <h4 className="popup__title">{title}</h4>
            <div className="popup__text">
              {parse(text)}
            </div>
            {tableData.length > 0 && (
              <div className="popup__table">
                <table>
                  <thead>
                    <tr>
                      {tableData[0].map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.slice(1).map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="popup__button">
            <Button width="fullWidth" hierarchy="primary" onClick={callback}>{t('popUps.button')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Popup;
