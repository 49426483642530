/* eslint-disable no-use-before-define, react/jsx-no-bind, max-len, no-underscore-dangle */
import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import './questions.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import i18n from 'i18next';
import { GetAlgorithmAPI } from '../../API/api';
import Nav from '../../components/Nav/Nav';
import Loading from '../Loading/Loading';
import Wdim from '../Wdim/Wdim';
import QuestionCard from '../QuestionCard/QuestionCard';
import Reassurance from '../Reassurance/Reassurance';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import PageNames from '../../utils/PageNames';
import PageTypes from '../../utils/PageTypes';
import MetricsContext from '../../context/MetricsContext';
import ButtonNames from '../../utils/ButtonNames';

export default function Questions() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [number, setNumber] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [questionsAmount, setQuestionsAmount] = useState(0);
  const [showReassurance, setShowReassurance] = useState(false);
  const [showWdim, setShowWdim] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressAmount, setProgressAmount] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [answerLogString, setAnswerLogString] = useState('');
  const mutationRef = useRef(answerLogString);
  const { language } = i18n;
  const ageGroup = location.state?.ageGroup;
  const symptom = location.state?.symptom;
  const { useUpdateUserMetric } = useContext(MetricsContext);

  const getAlgorithm = async () => {
    const data = await GetAlgorithmAPI(ageGroup, symptom, language);
    const response = await data.json();
    if (!data.ok) {
      navigate('/error', {
        state: { code: data.status, message: JSON.stringify(response) },
      });
      return null;
    }
    setAnswerLogString(response?.answerLog);
    return response?.questionList.map(question => ({
      ...question,
      answers: question.answerList,
      question: question.wdim,
    }));
  };

  useEffect(() => {
    async function fetchQuestions() {
      if (location.state === undefined) {
        navigate('/ageGroup');
      } else {
        const newQuestions = await getAlgorithm();
        setQuestions(newQuestions);
        const qlength = newQuestions?.length;
        const progressAmt = (100 / qlength) * 0.01;
        setProgressAmount(progressAmt);
        setQuestionsAmount(qlength);
        setLoading(false);
        updateProgressBar(progressAmt);

        useUpdateUserMetric(
          {
            name: PageNames.QUESTIONS_PAGE,
            pageType: PageTypes.QUESTION_METRIC,
            questionCode: newQuestions[number].code,
          },
          {
            algorithmData: {
              questionsList: newQuestions,
            },
          },
        );
      }
    }
    fetchQuestions();
  }, []);

  function showReassurancePage() {
    setShowReassurance(!showReassurance);
    const dateFinal = new Date();
    useUpdateUserMetric({
      name: PageNames.QUESTION_PAGE_REASSURANCE,
      pageType: PageTypes.DEFAULT,
      duration: Math.abs(dateFinal - startDate),
      interactions: [
        {
          name: ButtonNames.CONTINUE,
          time: new Date(),
        },
      ],
    });
  }

  function showWdimPage() {
    setShowWdim(!showWdim);
    const dateFinal = new Date();
    useUpdateUserMetric({
      name: PageNames.QUESTIONS_PAGE,
      pageType: PageTypes.QUESTION_METRIC,
      questionCode: questions[number].code,
      duration: Math.abs(dateFinal - startDate),
      interactions: [
        {
          name: ButtonNames.WDYM,
          time: new Date(),
        },
      ],
    });
  }

  const updateProgressBar = value => {
    setProgress(value);
  };

  useEffect(() => {
    const algorithmLog = answerLogString.split('.');
    const questions = algorithmLog[2];
    if (questions && questions.length === questionsAmount) {
      navigate('/output', {
        state: {
          language,
          answers: `${answerLogString}.${uuid()}`,
          symptom: location.state?.symptom,
        },
      });
    }
  }, [answerLogString]);

  useEffect(() => {
    mutationRef.current = answerLogString;
  }, [answerLogString]);

  const getRedAnswerOutput = async value => {
    const ansWeight = questions[number].answers[value - 1].weight;
    if (ansWeight === 3) {
      await setAnswerLogString(answerLogString + value);
      navigate('/output', {
        state: {
          language,
          answers: `${mutationRef.current}.${uuid()}`,
          redAnswer: true,
          symptom: location.state?.symptom,
        },
      });
    }
  };

  const nextQuestion = (e, answerText) => {
    const { value } = e.target;
    getRedAnswerOutput(value);
    const dateFinal = new Date();
    useUpdateUserMetric({
      name: PageNames.QUESTIONS_PAGE,
      pageType: PageTypes.QUESTION_METRIC,
      questionCode: questions[number].code,
      duration: Math.abs(dateFinal - startDate),
      interactions: [
        {
          name: answerText,
          time: dateFinal,
        },
      ],
    });

    let nextQ = number + 1;

    const answerCond = questions[number].answers[value - 1].conditional;
    if (answerCond !== '') {
      nextQ = questions.findIndex(question => question._id === answerCond);
    }

    // Failsafe: Does not permit the next question to go back in the question list
    if (nextQ <= number) nextQ = number + 1;

    // For each conditional question jumped, add a '0' after the value at answerLogString
    let condAmount = '';
    const conditionalPassed = nextQ - number;
    if (conditionalPassed > 1) {
      for (let index = 0; index < conditionalPassed - 1; index += 1) {
        condAmount += '0';
      }
    }

    // Set the answers and go for the next question (or output if none)
    if (
      nextQ === Math.floor(questionsAmount / 2)
      || (number < Math.floor(questionsAmount / 2)
        && nextQ > Math.floor(questionsAmount / 2))
    ) {
      setNumber(nextQ);
      setStartDate(new Date());
      if (condAmount) updateProgressBar(progress + condAmount.length * progressAmount);
      else updateProgressBar(progress + progressAmount);
      setAnswerLogString(answerLogString + value + condAmount);
      showReassurancePage();
    } else if (nextQ === questionsAmount) {
      setAnswerLogString(answerLogString + value + condAmount);
      setLoading(true);
    } else {
      setNumber(nextQ);
      setStartDate(new Date());
      if (condAmount) updateProgressBar(progress + condAmount.length * progressAmount);
      else updateProgressBar(progress + progressAmount);
      setAnswerLogString(answerLogString + value + condAmount);
    }

    // To remove the selected option.
    const options = document.getElementsByName('option');
    for (let i = 0; i < options.length; i += 1) {
      options[i].checked = false;
      const option = options[i].parentElement.parentElement;
      option.classList.remove('col_checkbox__component_checked', 'checked');
    }
  };

  const previousQuestion = () => {
    let conditional = false;
    let nextQ = number - 1;
    let repeated = 0;

    const logString = answerLogString.split('.');
    let myAnswer = answerLogString;
    if (logString[1] && myAnswer.slice(-1) === '0') conditional = true;

    // Set the answers and go for the next question (or output if none)
    if (conditional) {
      for (let i = 0; i < logString[2].length; i += 1) {
        if (myAnswer.slice(-1) === '0') {
          myAnswer = myAnswer.slice(0, -1);
          nextQ -= 1;
          repeated += 1;
        }
      }
      setNumber(nextQ);
      updateProgressBar(progress - progressAmount * repeated);
      setAnswerLogString(myAnswer.slice(0, -1));
      if (
        number > Math.floor(questionsAmount / 2)
        && nextQ < Math.floor(questionsAmount / 2)
      ) showReassurancePage();
    } else if (nextQ === Math.floor(questionsAmount / 2 - 1)) {
      setNumber(nextQ);
      updateProgressBar(progress - progressAmount);
      setAnswerLogString(answerLogString.slice(0, -1));
      showReassurancePage();
    } else if (nextQ === -1) navigate(-1);
    else {
      if (showReassurance) {
        showReassurancePage();
        nextQ += 1;
      }
      setNumber(nextQ);
      updateProgressBar(progress - progressAmount);
      setAnswerLogString(answerLogString.slice(0, -1));
    }
  };

  return (
    <>
      {loading && !showReassurance && <Loading />}
      {!loading && !showReassurance && (
        <div className="container">
          <Nav
            backArrow
            logo
            closeButton
            backArrowCallback={previousQuestion}
          />
          <ColoredBar />
          <div className="containerContent questionContent">
            <QuestionCard
              wdim={questions[number].wdim}
              question={questions[number].statement}
              answers={questions[number].answers}
              callback={nextQuestion}
              showWdimPage={showWdimPage}
              code={questions[number].code}
            />
          </div>
          <div className="questions__progress-bar">
            <ProgressBar width={100} percent={progress} />
          </div>
        </div>
      )}
      {!loading && showReassurance && !showWdim && (
        <Reassurance
          callback={showReassurancePage}
          previousQuestion={previousQuestion}
          progress={progress}
        />
      )}
      {!loading && !showReassurance && showWdim && (
        <Wdim text={questions[number].wdim} callback={showWdimPage} />
      )}
    </>
  );
}
