const PageNames = Object.freeze({
  GENERIC_DATA: 'Generic Page',
  LANGUAGE_PAGE: 'Language Page',
  ONBOARDING_PAGE: 'Onboarding Page',
  AGREEMENT_PAGE: 'Agreement Page',
  TERMS_PAGE: 'Terms Page',
  POLICY_PAGE: 'Policy Page',
  HOMEPAGE_PAGE: 'Homepage Page',
  MENU_PAGE: 'Menu Page',
  DISCLAIMER_PAGE: 'Disclaimer Page',
  EMERGENCY_PAGE: 'Emergency Page',
  AGE_GROUP_PAGE: 'Age Group Page',
  SYMPTOM_PAGE: 'Symptoms Page',
  OTHER_SYMPTOM_PAGE: 'Other Symptom Page',
  QUESTIONS_PAGE: 'Questions Page',
  QUESTION_PAGE_REASSURANCE: 'Question Reassurance Page',
  OUTPUT_PAGE: 'Output Page',
  FEEDBACK_PAGE: 'Feedback Page',
  FINAL_MESSAGE_PAGE: 'Final Message Page',
  CLINICAL_FEEDBACK_PAGE: 'Clinical Feedback Page',
  MODAL_PAGE: 'Modal Page',
});

export default PageNames;
