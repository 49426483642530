import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { addSuggestedSymptom } from '../../API/api';
import Button from '../../components/Button/Button';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import Nav from '../../components/Nav/Nav';
import Loading from '../Loading/Loading';
import './otherSymptom.css';
import PageNames from '../../utils/PageNames';
import PageTypes from '../../utils/PageTypes';
import MetricsContext from '../../context/MetricsContext';
import ButtonNames from '../../utils/ButtonNames';

function OtherSymptom() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = i18n;
  const [inputValue, setInputValue] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const ageGroup = location.state?.ageGroup;
  const startDate = new Date();

  const { useUpdateUserMetric } = useContext(MetricsContext);

  const sessionId = localStorage.getItem('sessionId');
  useEffect(() => {
    useUpdateUserMetric({
      name: PageNames.OTHER_SYMPTOM_PAGE,
      pageType: PageTypes.OTHER_SYMPTOM,
    });
  }, [sessionId]);

  const inputChangeHandler = e => {
    setInputValue(e.target.value);
  };

  const RedirectHandler = async () => {
    const dateFinal = new Date();
    setIsLoading(true);
    useUpdateUserMetric({
      name: PageNames.OTHER_SYMPTOM_PAGE,
      pageType: PageTypes.OTHER_SYMPTOM,
      userInput: inputValue,
      duration: Math.abs(dateFinal - startDate),
      interactions: [
        {
          name: ButtonNames.SUBMIT,
          time: dateFinal,
        },
      ],
    });

    if (sessionId && inputValue && ageGroup && language) {
      const data = await addSuggestedSymptom(sessionId, inputValue, ageGroup, language);
      if (typeof data === 'string') {
        navigate('/finalMessage', { state: { feedback: false } });
        return;
      }
      const response = await data.json();
      if (!data.ok) {
        navigate('/error', {
          state: { code: data.status, message: JSON.stringify(response) },
        });
      } else {
        navigate('/finalMessage', { state: { feedback: true } });
      }
    } else {
      navigate('/finalMessage', { state: { feedback: false } });
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <div className="container">
          <Nav backArrow logo />
          <ColoredBar />
          <div className="containerContent otherSymptom__content">
            <p className="otherSymptom__paragraph">{t('othersymptom.p1')}</p>
            <h3 className="otherSymptom__h3">{t('othersymptom.h3')}</h3>
            <p className="otherSymptom__paragraph">{t('othersymptom.p2')}</p>
            <input
              type="text"
              className="otherSymptom__input"
              placeholder="Enter your suggestion"
              onChange={inputChangeHandler}
            />
            <Button
              width="fullWidth"
              hierarchy="primary"
              disabled={!inputValue}
              onClick={RedirectHandler}
            >
              {t('othersymptom.button')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default OtherSymptom;
