/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import UK from '../../assets/designSystem/icons/United Kingdom.svg';
import './listRadio.css';

function ListRadio({
  disabled, checked, value, label, name, onChange, showFlag,

}) {
  function checkRadio() {
    const options = document.getElementsByName('option');
    for (let i = 0; i < options.length; i += 1) {
      const checkbox = options[i];
      const option = options[i].parentElement.parentElement;
      if (!checkbox.checked) {
        option.classList.remove('checked');
      } else {
        option.classList.add('checked');
      }
    }
  }

  const handleOnChange = (e, answerText) => {
    onChange(e, answerText);
  };
  return (
    <div
      style={disabled ? { pointerEvents: 'none', opacity: '0.2' } : {}}
    >
      <div className="checkbox__component">
        <label>
          <div className="listRadio__labelContainer">
            {
              showFlag && (<img src={UK} alt="UK flag" />)
            }
            <p className="bold radio-label">{label}</p>
          </div>
          <input
            type="radio"
            name={name}
            onClick={checkRadio}
            onChange={e => handleOnChange(e, label)}
            disabled={disabled}
            checked={checked}
            value={value}
          />
        </label>
      </div>
    </div>
  );
}

export default ListRadio;
