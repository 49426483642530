import React from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import {
  AgeGroup,
  Disclaimer,
  Emergency,
  HomePage,
  Questions,
  Splashscreen,
  Symptom,
  FinalMessage,
  Language,
  Onboarding,
  OtherSymptom,
  Agreement,
  PrivacyPolicy,
  TermsAndConditions,
  Menu,
  Output,
  Feedback,
  Loading,
  ErrorHandler,
  ClinicalFeedback,
  Test,
} from './pages';
import MetricsContextProvider from './context/MetricsContextProvider';

function App() {
  return (
    <MetricsContextProvider>
      <div className="app">
        <Router>
          <Routes>
            <Route exact path="/" element={<Splashscreen />} />
            <Route exact path="/homepage" element={<HomePage />} />
            <Route exact path="/disclaimer" element={<Disclaimer />} />
            <Route exact path="/ageGroup" element={<AgeGroup />} />
            <Route exact path="/emergency" element={<Emergency />} />
            <Route exact path="/symptom" element={<Symptom />} />
            <Route exact path="/questions" element={<Questions />} />
            <Route exact path="/finalMessage" element={<FinalMessage />} />
            <Route exact path="/output" element={<Output />} />
            <Route exact path="/onboarding" element={<Onboarding />} />
            <Route exact path="/otherSymptom" element={<OtherSymptom />} />
            <Route exact path="/agreement" element={<Agreement />} />
            <Route exact path="/policy" element={<PrivacyPolicy />} />
            <Route exact path="/terms" element={<TermsAndConditions />} />
            <Route exact path="/language" element={<Language />} />
            <Route exact path="/menu" element={<Menu />} />
            <Route exact path="/feedback" element={<Feedback />} />
            <Route exact path="/loading" element={<Loading />} />
            <Route exact path="/error" element={<ErrorHandler />} />
            <Route
              exact
              path="/clinicalFeedback/:researchId"
              element={<ClinicalFeedback />}
            />
            <Route exact path="/test" element={<Test />} />
          </Routes>
        </Router>
      </div>
    </MetricsContextProvider>
  );
}

export default App;
