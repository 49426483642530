const PageTypes = Object.freeze({
  DEFAULT: 'Default',
  EMERGENCY: 'Emergency',
  SYMPTOM_METRIC: 'SymptomMetric',
  OTHER_SYMPTOM: 'OtherSymptom',
  QUESTION_METRIC: 'QuestionMetric',
  OUTPUT_METRIC: 'OutputMetric',
  FEEDBACK_METRIC: 'FeedbackMetric',
  CLINICAL_FEEDBACK_METRIC: 'ClinicalFeedbackMetric',
});

export default PageTypes;
