const ButtonNames = Object.freeze({
  CONTINUE: 'continue',
  CONTINUE_NEW: 'assessment',
  EMERGENCY: 'emergency',
  OTHER: 'other',
  WDYM: 'what do you mean',
  STARTASSESMENT: 'startAssessment',
  LANGUAGE: 'language',
  SKIP: 'skip',
  SUBMIT: 'submit',
});

export default ButtonNames;
