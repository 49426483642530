/* eslint-disable import/prefer-default-export, react/jsx-no-bind */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './emergency.css';
import { useNavigate } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import Button from '../../components/Button/Button';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import PageNames from '../../utils/PageNames';
import PageTypes from '../../utils/PageTypes';
import MetricsContext from '../../context/MetricsContext';

export default function Emergency() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { useUpdateUserMetric, loading } = useContext(MetricsContext);
  const sessionId = localStorage.getItem('sessionId');
  const startDate = new Date();
  useEffect(() => {
    useUpdateUserMetric({
      name: PageNames.EMERGENCY_PAGE,
      pageType: PageTypes.DEFAULT,
    });
  }, [sessionId]);

  function handleContinue() {
    const dateFinal = new Date();
    useUpdateUserMetric({
      name: PageNames.EMERGENCY_PAGE,
      duration: Math.abs(dateFinal - startDate),
      interactions: [
        {
          name: 'continue',
          time: dateFinal,
        },
      ],
    });
    if (!loading) {
      navigate('/ageGroup');
    }
  }

  function handleEmergencyClick() {
    const dateFinal = new Date();
    useUpdateUserMetric({
      name: PageNames.EMERGENCY_PAGE,
      pageType: PageTypes.DEFAULT,
      duration: Math.abs(dateFinal - startDate),
      interactions: [
        {
          name: 'emergency',
          time: dateFinal,
        },
      ],
    });
  }

  return (
    <div className="container">
      <Nav backArrow logo closeButton />
      <ColoredBar />
      <div className="containerContent emergency__page">
        <p>{t('emergencypage.p1')}</p>
        <br />
        <p>{t('emergencypage.p2')}</p>
        <br />
        <p>{t('emergencypage.p3')}</p>
        <ul>
          <li>
            <p>{t('emergencypage.li1')}</p>
          </li>
          <li>
            <p>{t('emergencypage.li2')}</p>
          </li>
          <li>
            <p>{t('emergencypage.li3')}</p>
          </li>
        </ul>
        <div className="emergency__bottomwrapper">
          <Button
            width="fullWidth"
            hierarchy="primary"
            onClick={handleContinue}
            additionalClass=" agree"
          >
            {t('emergencypage.button1')}
          </Button>
          <a
            onClick={handleEmergencyClick}
            className="emergency__callBtn"
            href="tel:0800721316"
          >
            {t('emergencypage.button2')}
          </a>
        </div>
      </div>
    </div>
  );
}
