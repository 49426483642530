import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import Nav from '../../components/Nav/Nav';
import rightArrow from '../../assets/designSystem/icons/simple-arrow/right.svg';
import './menu.css';
import MetricsContext from '../../context/MetricsContext';
import PageNames from '../../utils/PageNames';
import PageTypes from '../../utils/PageTypes';

function Menu() {
  const { t } = useTranslation();
  const { useUpdateUserMetric } = useContext(MetricsContext);
  const startDate = new Date();

  const sessionId = localStorage.getItem('sessionId');
  useEffect(() => {
    useUpdateUserMetric({
      name: PageNames.MENU_PAGE,
      pageType: PageTypes.DEFAULT,
    });
  }, [sessionId]);

  const updateMetrics = btn => {
    const dateFinal = new Date();
    useUpdateUserMetric({
      name: PageNames.MENU_PAGE,
      pageType: PageTypes.DEFAULT,
      duration: Math.abs(dateFinal - startDate),
      interactions: [
        {
          name: btn,
          time: dateFinal,
        },
      ],
    });
  };

  return (
    <div className="container">
      <Nav backArrow label="Settings & Info" />
      <ColoredBar />
      <div className="containerContent menu__container">
        <ul className="menu__ul">
          <li>
            <Link to="/terms" className="menu__itemContainer" onClick={() => updateMetrics('Terms & Conditions')}>
              <p className="bold">{t('menu.tc')}</p>
              <img src={rightArrow} alt="logo" />
            </Link>
          </li>
          <li>
            <Link to="/policy" className="menu__itemContainer" onClick={() => updateMetrics('Privacy Policy')}>
              <p className="bold">{t('menu.privacy')}</p>
              <img src={rightArrow} alt="logo" />
            </Link>
          </li>
          <li>
            <a
              href="https://www.kitohealth.org"
              target="_blank"
              rel="noreferrer"
              className="menu__itemContainer"
              onClick={() => updateMetrics('Website')}
            >
              <p className="bold">{t('menu.website')}</p>
              <img src={rightArrow} alt="logo" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Menu;
