// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.symptom__content {
  gap: var(--spacing-l);
  align-items: center;
  text-align: center;
}

.symptom__buttons {
  width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
  max-width: 65vh;
  max-height: 65vh;
	gap: var(--spacing-xs);
}

.load {
  margin: 0 auto;
  width: 20px;
  aspect-ratio: 1;
  background: #25b09b;
  box-shadow: 0 0 60px 15px #25b09b;
  transform: translate(-80px);
  clip-path: inset(0);
  animation:
    l4-1 0.5s ease-in-out infinite alternate,
    l4-2 1s   ease-in-out infinite;
}
@keyframes l4-1 {
  100% {transform: translateX(80px)}
}
@keyframes l4-2 {
   33% {clip-path: inset(0 0 0 -100px)}
   50% {clip-path: inset(0 0 0 0)     }
   83% {clip-path: inset(0 -100px 0 0)}
}`, "",{"version":3,"sources":["webpack://./src/pages/Symptom/symptom.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;CACZ,aAAa;CACb,8BAA8B;EAC7B,eAAe;EACf,gBAAgB;CACjB,sBAAsB;AACvB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,eAAe;EACf,mBAAmB;EACnB,iCAAiC;EACjC,2BAA2B;EAC3B,mBAAmB;EACnB;;kCAEgC;AAClC;AACA;EACE,MAAM,2BAA2B;AACnC;AACA;GACG,KAAK,8BAA8B;GACnC,KAAK,8BAA8B;GACnC,KAAK,8BAA8B;AACtC","sourcesContent":[".symptom__content {\n  gap: var(--spacing-l);\n  align-items: center;\n  text-align: center;\n}\n\n.symptom__buttons {\n  width: 100%;\n\tdisplay: grid;\n\tgrid-template-columns: 1fr 1fr;\n  max-width: 65vh;\n  max-height: 65vh;\n\tgap: var(--spacing-xs);\n}\n\n.load {\n  margin: 0 auto;\n  width: 20px;\n  aspect-ratio: 1;\n  background: #25b09b;\n  box-shadow: 0 0 60px 15px #25b09b;\n  transform: translate(-80px);\n  clip-path: inset(0);\n  animation:\n    l4-1 0.5s ease-in-out infinite alternate,\n    l4-2 1s   ease-in-out infinite;\n}\n@keyframes l4-1 {\n  100% {transform: translateX(80px)}\n}\n@keyframes l4-2 {\n   33% {clip-path: inset(0 0 0 -100px)}\n   50% {clip-path: inset(0 0 0 0)     }\n   83% {clip-path: inset(0 -100px 0 0)}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
