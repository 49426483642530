// const baseUrl = 'https://api.kitohealth.org';
// const baseUrl = `${import.meta.env.REACT_APP_ENVIRONMENT}`;
const baseUrl = 'https://api.develop.kitohealth.org';
// const baseUrl = 'https://api.kitohealth.org/subscribe';
// const baseUrl = 'http://localhost:8000';

// Feedback Component
export async function addRatingAPI(userId, journeyId, rating, message) {
  const mode = localStorage.getItem('mode');

  if (mode === 'test') {
    return Promise.resolve('Mode is test, request skipped.');
  }

  return fetch(`${baseUrl}/rating`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId,
      journeyId,
      rating,
      message,
    }),
  });
}

// ClinicalFeedback Component
export async function AddClinicalFeedbackAPI(userId, journeyId, questionnaire) {
  const mode = localStorage.getItem('mode');

  if (mode === 'test') {
    return Promise.resolve('Mode is test, request skipped.');
  }

  return fetch(`${baseUrl}/clinicalFeedback`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId,
      journeyId,
      questionnaire,
    }),
  });
}

// OtherSymptom Component
export async function addSuggestedSymptom(
  userId,
  suggestedSymptom,
  ageGroup,
  language,
) {
  const mode = localStorage.getItem('mode');

  if (mode === 'test') {
    return Promise.resolve('Mode is test, request skipped.');
  }

  return fetch(`${baseUrl}/suggestedSymptom`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      userId,
      suggestedSymptom,
      ageGroup,
      language,
    }),
  });
}

// Questions Component
export async function GetAlgorithmAPI(ageGroup, symptom, language) {
  return fetch(`${baseUrl}/algorithms/${ageGroup}${symptom}/${language}`);
}

// TO DO: Change localhost to development url (the base url above)
// Output Component
export async function GetRedOutputAPI(params, userId) {
  return fetch(`${baseUrl}/algorithms/output/redAnswer/${params}/${userId}`);
}

export async function GetOutputAPI(params, userId) {
  return fetch(`${baseUrl}/algorithms/output/${params}/${userId}`);
}

export function sendNotification(sub) {
  fetch(`${baseUrl}/send-notification`, {
    method: 'POST',
    body: JSON.stringify(sub),
    headers: {
      'content-type': 'application/json',
    },
  });
}

export function getSymptoms(language, ageGroup) {
  return fetch(`${baseUrl}/symptoms/language/${language}/age/${ageGroup}`);
}

export function getUserMetric(sessionId) {
  return fetch(`${baseUrl}/page-metric/${sessionId}`);
}

export function createUserMetric(metric) {
  const mode = localStorage.getItem('mode');

  if (mode === 'test') {
    return Promise.resolve('Mode is test, request skipped.');
  }

  return fetch(`${baseUrl}/page-metric`, {
    method: 'POST',
    body: JSON.stringify(metric),
    headers: {
      'content-type': 'application/json',
    },
  });
}

export function updateUserMetric(sessionId, metric) {
  const mode = localStorage.getItem('mode');

  if (mode === 'test') {
    return Promise.resolve('Mode is test, request skipped.');
  }

  return fetch(`${baseUrl}/page-metric/${sessionId}`, {
    method: 'PUT',
    body: JSON.stringify(metric),
    headers: {
      'content-type': 'application/json',
    },
  });
}

export function getPopups() {
  return fetch(`${baseUrl}/popups`);
}
// export function userSubscribe(subscription) {
//   fetch(`${baseUrl}/subscribe`, {
//     method: 'POST',
//     body: JSON.stringify(subscription),
//     headers: {
//       'content-type': 'application/json',
//     },
//   });
// }
