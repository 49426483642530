import React, { useReducer, useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import MetricContext from './MetricsContext';
import { createUserMetric, getUserMetric, updateUserMetric } from '../API/api';
import PageNames from '../utils/PageNames';
import ButtonNames from '../utils/ButtonNames';

// Define action types
const SET_USER_METRIC = 'SET_USER_METRIC';
const UPDATE_USER_METRIC = 'UPDATE_USER_METRIC';
const SET_ERROR = 'SET_ERROR';
const SET_LOADING = 'SET_LOADING';

// Initial state
const initialState = {
  metric: null,
  error: null,
  loading: false, // Add loading state
};

// Reducer function
function reducer(state, action) {
  switch (action.type) {
    case SET_USER_METRIC:
      return {
        ...state, metric: action.payload, error: null, loading: false,
      };
    case UPDATE_USER_METRIC:
      return {
        ...state, metric: action.payload, error: null, loading: false,
      };
    case SET_ERROR:
      return {
        ...state, error: action.payload, loading: false,
      };
    case SET_LOADING:
      return {
        ...state, loading: action.payload,
      };
    default:
      return state;
  }
}
const mergePages = pages => {
  const merged = {};
  pages.forEach(page => {
    const {
      name,
      pageType,
      duration,
      interactions,
    } = page;

    if (!merged[name]) {
      merged[name] = {
        name,
        pageType,
        duration: 0,
        interactions: [],
      };
    }
    if (duration) {
      merged[name].duration += duration; // Summing up the durations
    }

    if (interactions) {
      merged[name].interactions = merged[name].interactions.concat(interactions); // Merging interactions
    }
  });
  return Object.values(merged);
};
function MetricsContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setLoading = useCallback(isLoading => {
    dispatch({ type: SET_LOADING, payload: isLoading });
  }, []);

  const storeLocalMetrics = useCallback(
    newInteraction => {
      // Retrieve and parse the existing metrics from localStorage
      const localMetric = JSON.parse(localStorage.getItem('localMetric')) || [];

      // Append the new interaction
      const updatedMetrics = [
        ...localMetric,
        newInteraction,
      ];
      // Store the updated metrics back to localStorage
      localStorage.setItem('localMetric', JSON.stringify(updatedMetrics));
    },
    [],
  );

  const initMetrics = useCallback(async () => {
    setLoading(true);
    try {
      const sessionId = localStorage.getItem('sessionId');
      const lang = localStorage.getItem('i18nextLng');
      const langEle = lang ? { language: lang } : {};
      const localMetric = mergePages(JSON.parse(localStorage.getItem('localMetric')) || []);
      if (sessionId) {
        const response = await getUserMetric(sessionId);
        const data = await response.json();
        dispatch({ type: SET_USER_METRIC, payload: data });
      } else {
        const id = `user${uuid()}`;
        const response = await createUserMetric({
          sessionId: id,
          journeys: [{
            startAppTime: new Date(),
            pages: [...localMetric],
            ...langEle,
          }],
        });

        const data = await (typeof response === 'string' ? response : response.json());
        if (typeof data !== 'string') {
          localStorage.setItem('sessionId', id);
          dispatch({ type: SET_USER_METRIC, payload: data });
        }
      }
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: error });
      throw new Error('Error fetching or creating user metric:', error);
    } finally {
      setLoading(false);
    }
  }, [setLoading]);

  const useUpdateUserMetric = useCallback(async (
    newInteraction,
    updateJourneyInformation = {},
    newGeneralInformation = {},
  ) => {
    // Check if the metric is loaded, if not, fetch it
    if (!state.metric) {
      const sessionId = localStorage.getItem('sessionId');
      if (sessionId) {
        const response = await getUserMetric(sessionId);
        const data = await response.json();
        dispatch({ type: SET_USER_METRIC, payload: data });
        return; // Return to ensure the state is updated before continuing
      }
      return;
    }

    const updatedJourneys = [...(state?.metric?.journeys || [])];
    const lastJourneyIndex = updatedJourneys.length - 1;
    const lastJourney = updatedJourneys[lastJourneyIndex];

    const isNewInteractionStartAssessment = Array.isArray(newInteraction?.interactions)
      && newInteraction.interactions.length > 0
      && newInteraction?.interactions.some(e => e.name === ButtonNames.STARTASSESMENT);

    // Check if a new journey should be created
    /* eslint-disable max-len */

    const shouldCreateNewJourney = lastJourney.pages.some(page => page.interactions.some(interaction => interaction.name === ButtonNames.STARTASSESMENT))
      && isNewInteractionStartAssessment;

    if (shouldCreateNewJourney) {
      const lang = localStorage.getItem('i18nextLng');
      // Create a new journey
      const newJourney = {
        startAppTime: new Date(),
        pages: [{
          ...newInteraction,
          name: newInteraction.name,
          interactions: newInteraction.interactions,
          pageType: newInteraction.pageType || 'Default',
        }],
        language: lang || newInteraction.language || 'Unknown',
        algorithmData: {
          questionsList: updateJourneyInformation.algorithmData?.questionsList || [],
          outputsList: updateJourneyInformation.algorithmData?.outputsList || [],
        },
        ...updateJourneyInformation,
      };
      updatedJourneys.push(newJourney);
    } else if (lastJourney) {
      const updatedPages = [...lastJourney.pages];

      const pageIndex = updatedPages.findLastIndex(page => {
        if (page.name === PageNames.QUESTIONS_PAGE) {
          return page.questionCode === newInteraction.questionCode;
        }
        return page.name === newInteraction.name;
      });

      const existingPage = pageIndex !== -1 ? updatedPages[pageIndex] : null;
      const isLastPage = pageIndex === updatedPages.length - 1;
      // Only proceed if it's the last page and it exists
      if (existingPage && isLastPage) {
        // If the page already exists, update its interactions
        const updatedInteractions = [...(existingPage.interactions || []), ...(newInteraction.interactions || [])];
        updatedPages[pageIndex] = { ...existingPage, ...newInteraction, interactions: updatedInteractions };
      } else if (!isLastPage) {
        // If the last page does not exist or does not match, create a new page
        const newPage = {
          ...newInteraction,
          name: newInteraction.name,
          interactions: newInteraction.interactions,
          pageType: newInteraction.pageType || 'Default',
        };
        updatedPages.push(newPage);
      }

      const mergedAlgorithmData = {
        ...lastJourney.algorithmData,
        ...updateJourneyInformation.algorithmData,
        questionsList:
          updateJourneyInformation.algorithmData?.questionsList || lastJourney.algorithmData?.questionsList,
        outputsList: updateJourneyInformation.algorithmData?.outputsList || lastJourney.algorithmData?.outputsList,
      };
      const durationFinalJourney = Math.abs(new Date() - new Date(lastJourney.startAppTime));
      updatedJourneys[lastJourneyIndex] = {
        ...lastJourney,
        pages: updatedPages,
        endAppTime: new Date(),
        totalAppTime: durationFinalJourney,
        algorithmData: mergedAlgorithmData,
        ...updateJourneyInformation,
      };
    } else {
      // If there is no last journey, create a new one
      const newJourney = {
        startAppTime: new Date(),
        pages: [{
          ...newInteraction,
          name: newInteraction.name,
          interactions: newInteraction.interactions,
          pageType: newInteraction.pageType || 'Default',
        }],
        language: newInteraction.language || 'Unknown',
        algorithmData: {
          questionsList: updateJourneyInformation.algorithmData?.questionsList || [],
          outputsList: updateJourneyInformation.algorithmData?.outputsList || [],
        },
        ...updateJourneyInformation,
      };
      updatedJourneys.push(newJourney);
    }

    const updatedMetric = { ...state.metric, journeys: updatedJourneys, ...newGeneralInformation };
    dispatch({ type: UPDATE_USER_METRIC, payload: updatedMetric });

    try {
      setLoading(true);
      const response = await updateUserMetric(state.metric.sessionId, updatedMetric);
      const data = await (typeof response === 'string' ? response : response.json());
      if (typeof data !== 'string') {
        dispatch({ type: SET_USER_METRIC, payload: data });
        localStorage.setItem('sessionId', data.sessionId);
      }
    } catch (error) {
      dispatch({ type: SET_ERROR, payload: error });
      throw new Error('Error updating user metric:', error);
    } finally {
      setLoading(false);
    }
  }, [state.metric, setLoading]);

  return (
    <MetricContext.Provider
      value={useMemo(
        () => ({
          ...state,
          useUpdateUserMetric,
          initMetrics,
          storeLocalMetrics,
        }),
        [state, useUpdateUserMetric, initMetrics, storeLocalMetrics],
      )}
    >
      {children}
    </MetricContext.Provider>
  );
}

export default MetricsContextProvider;
