/* eslint-disable react/jsx-no-bind, import/prefer-default-export */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './homepage.css';
import Nav from '../../components/Nav/Nav';
import arrow from '../../assets/designSystem/icons/arrow/right.svg';
import Button from '../../components/Button/Button';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import MetricsContext from '../../context/MetricsContext';
import PageNames from '../../utils/PageNames';
import PageTypes from '../../utils/PageTypes';
import ButtonNames from '../../utils/ButtonNames';

export default function HomePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const startDate = new Date();
  const { useUpdateUserMetric } = useContext(MetricsContext);
  const sessionId = localStorage.getItem('sessionId');
  useEffect(() => {
    useUpdateUserMetric({
      name: PageNames.HOMEPAGE_PAGE,
      pageType: PageTypes.DEFAULT,
    });
  }, [sessionId]);

  function handleRedirect() {
    const dateFinal = new Date();
    useUpdateUserMetric({
      name: PageNames.HOMEPAGE_PAGE,
      pageType: PageTypes.DEFAULT,
      duration: Math.abs(dateFinal - startDate),
      interactions: [
        {
          name: ButtonNames.STARTASSESMENT,
          time: dateFinal,
        },
      ],
    });
    navigate('/loading', { state: { nextPage: '/disclaimer' } });
    // ReactGA.event({
    //   category: 'Button',
    //   action: 'Started triage',
    // });
  }
  return (
    <div className="container">
      <Nav homepage />
      <div className="containerContent home__content">
        <div>
          <h2>{t('homepage.homeh2')}</h2>
          <p className="home__content__p">{t('homepage.paragraph')}</p>
          <p className="home__content__p">{t('homepage.contentp')}</p>
        </div>
        <Button
          width="fullWidth"
          hierarchy="primary"
          type="button"
          onClick={handleRedirect}
          additionalClass=" start"
        >
          <span>{t('homepage.button')}</span>
          <img src={arrow} height={16} alt="arrow-right" />
        </Button>
      </div>
      <ColoredBar big="bigColoredBar" />
    </div>
  );
}
