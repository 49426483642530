import React, { useContext, useEffect } from 'react';
import ColoredBar from '../../components/ColoredBar/ColoredBar';
import Nav from '../../components/Nav/Nav';
import './privacypolicy.css';
import MetricsContext from '../../context/MetricsContext';
import PageNames from '../../utils/PageNames';
import PageTypes from '../../utils/PageTypes';

function PrivacyPolicy() {
  const { useUpdateUserMetric } = useContext(MetricsContext);

  const sessionId = localStorage.getItem('sessionId');
  useEffect(() => {
    useUpdateUserMetric({
      name: PageNames.POLICY_PAGE,
      pageType: PageTypes.DEFAULT,
    });
  }, [sessionId]);
  return (
    <div className="container">
      <Nav backArrow label="Privacy Policy" />
      <ColoredBar />
      <div className="containerContent">
        <h4 className="privacy__first-title">PRIVACY POLICY</h4>
        <p>
          We care about you and the privacy of your personal data. For us, it is
          essential and necessary to provide a service to our customers and
          partners, as safe and confidential as possible, safeguarding the
          privacy of communications and their personal data. This privacy policy
          sets out the ways to collect, process and use personal data, as well
          as the security safeguards adopted in this treatment for both the
          services and products, we make available.
        </p>
        <h4 className="privacy__titles">Access to Personal Data</h4>
        <p>
          This privacy policy aims to demonstrate our commitment and respect for
          the rules of privacy and protection of personal data of customers and
          partners and subscribers to our website.
          <br />
          <br />
          In other words, by subscribing to a service or product of our company,
          you are giving and authorizing their treatment exclusively by the
          company Kitohealth, either, through our website or through E-mail, it
          implies the introduction of the customer&apos;s data into the system,
          of personal identification data, as well as aspects related to their
          needs, special circumstances or other data, which will only be used to
          analyze the specific data that make up the customer&apos;s request, as
          well as to provide information regarding products and services.
          <br />
          <br />
          Under no circumstances will information be requested about
          philosophical or political convictions, party or trade union
          affiliation, religious faith, private life and racial or ethnic
          origin.
          <br />
          <br />
          We will, however, request some private data relating to health and sex
          life, including genetic data.
          <br />
          <br />
          The Processing of Personal Data is carried out in strict compliance
          with the legislation for the protection of personal data, all in
          accordance with the provisions of the General Data Protection
          Regulation (EU) 2016/679 and/or any legislation that regulates, adds
          or replaces said legislation, from now on called GDPR.
          <br />
          <br />
          Any personal data that, in the use of this system, customers or
          partners may reveal will be understood as obtained, processed and
          transmitted, with their consent, under strict compliance with the
          RGPD.
          <br />
          <br />
          Kitohealth declares and guarantees that it has implemented, is
          equipped and will continue to implement the security measures of a
          technical and organizational nature necessary to guarantee the
          security of the personal data provided to it in order to prevent its
          alteration, loss, treatment and/or access. unauthorized, taking into
          account the current state of technology, the nature of the data stored
          and minimizing the risks to which they are exposed.
          <br />
          <br />
          Kitohealth guarantees customers and partners the exercise of legally
          provided access, rectification or elimination rights. If the customer
          or partner wishes to exercise the aforementioned rights that the RGPD
          grants them, they must send an email to rgpd@multidados.com
        </p>
        <h4 className="privacy__titles">Data collection</h4>
        <p>
          Kitohealth collects, from its customers and partners, some personal
          data such as: email address, first and last name, address and NIF.
          This collection is done through the website or directly by the
          company&apos;s commercials.
          <br />
          <br />
          The collection of your data is always done with prior consent, clearly
          indicating the purpose for which they are intended. The data collected
          is adequate, relevant and not excessive in relation to the scope,
          purpose and specified, explicit and legitimate products and services
          by Kitohealth.
        </p>
        <h4 className="privacy__titles">Processing of Personal Data</h4>
        <p>
          Kitohealth is responsible for processing the personal data of its
          customers and/or potential customers who fill in their data.
          <br />
          <br />
          The Personal Data obtained are incorporated into Kitohealth&apos;s
          computer application. Access to archived information by Kitohealth
          employees is only possible with the insertion of exclusive passwords,
          leaving the record documented. Changes to existing data in the files
          are possible, leaving a record of the date and the user code
          responsible for the change.
          <br />
          <br />
          Personal Data are treated with the degree of protection legally
          required to guarantee their security and prevent their alteration,
          loss, treatment or unauthorized access, with the user being aware and
          accepting that Internet security measures are not impregnable.
          <br />
          <br />
          Kitohealth is equipped with technical infrastructures for peripheral
          control, namely anti-virus, network firewalls, encryption systems,
          private circuits and VPNs that respect security requirements.
          <br />
          <br />
          Kitohealth, accessing any personal data, undertakes to:
          <br />
          <br />
          Treat them in accordance with the applicable data protection
          requirements, through legally required security measures, of a
          technical and organizational nature, that guarantee their security,
          thus preventing their alteration, loss, treatment or unauthorized
          access, in compliance with the state of the technology at any given
          time, the nature of the data and the possible risks to which they are
          exposed;
          <br />
          <br />
          Use or apply the data exclusively for the purposes duly consented to;
          <br />
          <br />
          Make sure that the data is handled only by the workers whose
          intervention is necessary for the provision of the service, who are
          bound by the duty of secrecy and confidentiality. If there is a
          possibility that the information may be disclosed to third parties,
          they must be obliged to maintain the necessary confidentiality in
          accordance with the provisions of this document.
        </p>
        <h4 className="privacy__titles">
          Purpose and Legal Basis for Data Processing
        </h4>
        <p>
          You can freely browse the Kitohealth website without having to provide
          any personal data.
          <br />
          <br />
          The purpose of data processing for subscribers registered in the
          newsletter / is exclusively for sending the respective surveys to
          respond, newsletters, information, and promotions as well as the
          latest news of Kitohealth activity.
          <br />
          <br />
          In the case described above, data processing is always carried out by
          Kitohealth and its partners.
          <br />
          <br />
          Your data will never be transferred to third parties for any purpose.
          <br />
          The processing of subscribers&apos; data is only carried out with
          their clear, free and informed consent, through a proactive action
          aimed at said consent.
        </p>
        <h4 className="privacy__titles">
          Responsible for the Processing of Personal Data
        </h4>
        <p>
          Kitohealth is responsible for processing the collection, registration,
          organization, structuring, conservation, adaptation or alteration,
          recovery, consultation, use, dissemination by transmission,
          dissemination or any other form of making available, comparing or
          interconnection, limitation, erasure or destruction of the Personal
          Data of its users, customers or potential customers.
        </p>
        <h4 className="privacy__titles">Data retention period</h4>
        <p>
          Customer and partner data are only kept for the period necessary to
          fulfill the purposes for which they are intended.
          <br />
          The customer may request, at any time, to change or withdraw his
          consent with effect for the future.
        </p>
        <h4 className="privacy__titles">
          Access, Rectification or Opposition to Data Processing
        </h4>
        <p>
          The Customer may exercise their rights of access, rectification,
          cancellation and opposition with regard to their personal data. The
          exercise of said rights must be carried out by mail to the
          company&apos;s address or by email to rgpd@multidados.com
        </p>
        <h4 className="privacy__titles">Changes to the Privacy Policy</h4>
        <p>
          Kitohealth reserves the right, at any time, to update or revise the
          privacy policy indicated herein to adjust to possible legislative
          changes and other restrictions.
          <br />
          <br />
          The privacy policy in force (with any necessary changes) can be
          consulted at any time in this same space.
        </p>
        <h4 className="privacy__titles">Acceptance of the Privacy Policy</h4>
        <p>
          It is understood that the customer accepts the terms of this privacy
          policy by providing their data in any of the possible ways to contact
          our company. we assume that you authorize the automated processing and
          use of data in accordance with the privacy policy described herein.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
