/* eslint-disable no-mixed-operators */
/* eslint-disable react/jsx-no-bind, import/prefer-default-export */
import React, { useContext, useEffect, useState } from 'react';
import './language.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SquareRadio from '../../components/Radio/SquareRadio';
import En from '../../assets/designSystem/icons/United Kingdom.svg';
import Swa from '../../assets/designSystem/icons/Kenya.svg';
import Button from '../../components/Button/Button';
import MetricsContext from '../../context/MetricsContext';
import PageNames from '../../utils/PageNames';
import PageTypes from '../../utils/PageTypes';

export default function Language() {
  const navigate = useNavigate();
  const [activeBtn, setActiveBtn] = useState(true);
  const [language, setLanguage] = useState();
  const startDate = new Date();

  const { i18n, t } = useTranslation();

  const { useUpdateUserMetric, storeLocalMetrics } = useContext(MetricsContext);

  const sessionId = localStorage.getItem('sessionId');

  useEffect(() => {
    if (sessionId) {
      useUpdateUserMetric({
        name: PageNames.LANGUAGE_PAGE,
        pageType: PageTypes.DEFAULT,
      });
    } else {
      storeLocalMetrics({
        name: PageNames.LANGUAGE_PAGE,
        pageType: PageTypes.DEFAULT,
      });
    }
  }, [sessionId]);

  function handleRedirect() {
    const dateFinal = new Date();
    if (sessionId) {
      useUpdateUserMetric({
        name: PageNames.LANGUAGE_PAGE,
        pageType: PageTypes.DEFAULT,
        duration: Math.abs(dateFinal - startDate),
        interactions: [
          {
            name: language,
            time: dateFinal,
          },
        ],
      });
    } else {
      storeLocalMetrics({
        name: PageNames.LANGUAGE_PAGE,
        pageType: PageTypes.DEFAULT,
        duration: Math.abs(dateFinal - startDate),
        interactions: [
          {
            name: language,
            time: dateFinal,
          },
        ],
      });
    }
    navigate('/onboarding');
  }

  function handleClick(e) {
    if (e.target.value !== '') {
      setActiveBtn(false);
      setLanguage(e.target.value);
      i18n.changeLanguage(e.target.value);
    }
  }
  return (
    <div className="container">
      <div className="containerContent language__page">
        <div>
          <h3 className="language__center">{t('languagepage.start')}</h3>
          <p className="language__paragraph">{t('languagepage.selectlang')}</p>
          <div id="test" className="language__radios">
            <SquareRadio
              name="option"
              image={En}
              label="English"
              value="English"
              onClick={handleClick}
            />
            <SquareRadio
              name="option"
              image={Swa}
              label="Swahili"
              value="Swahili"
              onClick={handleClick}
            />
          </div>
        </div>
        <div className="language__page__button">
          <Button
            hierarchy="primary"
            width="fullWidth"
            disabled={activeBtn}
            onClick={handleRedirect}
            additionalClass=" continue"
          >
            {t('continue')}
          </Button>
        </div>
      </div>
    </div>
  );
}
