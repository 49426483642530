import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Test() {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('mode', 'test');
    navigate('/');
  }, []);

  return null;
}
