import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import closeButtonPic from '../../assets/designSystem/icons/close.svg';
import Button from '../Button/Button';
import './modal.css';
import MetricsContext from '../../context/MetricsContext';
import PageNames from '../../utils/PageNames';
import PageTypes from '../../utils/PageTypes';

function Modal({ onCloseModal }) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { useUpdateUserMetric } = useContext(MetricsContext);
  const startDate = new Date();

  const sessionId = localStorage.getItem('sessionId');
  useEffect(() => {
    useUpdateUserMetric({
      name: PageNames.MODAL_PAGE,
      pageType: PageTypes.DEFAULT,
    });
  }, [sessionId]);

  const updateMetrics = btn => {
    const dateFinal = new Date();
    useUpdateUserMetric({
      name: PageNames.MENU_PAGE,
      pageType: PageTypes.DEFAULT,
      duration: Math.abs(dateFinal - startDate),
      interactions: [
        {
          name: btn,
          time: dateFinal,
        },
      ],
    });
  };

  const redirectHandler = () => {
    updateMetrics('Yes, quit assessment');
    navigate('/homepage', { state: location.state });
  };

  const onCloseModalHandler = () => {
    updateMetrics('No, continue assessment');
    onCloseModal();
  };

  return (
    <>
      <div className="modal__backdrop" role="presentation" onClick={onCloseModal} />
      <div className="modal__popup">
        <div className="modal__content">
          <img
            src={closeButtonPic}
            alt="close button"
            className="modal__closeBtn"
            role="presentation"
            onClick={onCloseModal}
          />
          <div>
            <h3 className="modal__header">{t('modalcomponent.h3')}</h3>
            <p className="modal__paragraph">{t('modalcomponent.p')}</p>
          </div>
          <div className="modal__containerButtons">
            <Button width="fullWidth" hierarchy="primary" onClick={onCloseModalHandler}>
              {t('modalcomponent.button1')}
            </Button>
            <Button width="fullWidth" hierarchy="tertiary" onClick={redirectHandler}>
              {t('modalcomponent.button2')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
