import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './i18n';

const isLocal = window.location.hostname === 'localhost';

if (!isLocal) {
  Sentry.init({
    dsn: 'https://301b3750145951e74384832724a74f1f@o4508358635749376.ingest.de.sentry.io/4508403868500048',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/kitohealth\.org\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactGA.initialize('G-RHL5YDTJ49');

createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
